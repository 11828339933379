<template>
  <div class="about">
    <div class="about__header">
      <div class="about__image">
        <img class="image" src="@/assets/img/king.png" alt="Nick" />
      </div>
      <div class="about__header-text">
        <h2>A little about me ...</h2>
      </div>
    </div>
    <div class="about__content">
      <div class="about__content-section">
        <h2 class="about__content-title">I'm Nick...</h2>
        <div class="about__content-text">
          <p>
            Based in Manchester, UK, I am a self-taught web and app developer. I
            began with a solid foundation in HTML, CSS, and JavaScript.
            Subsequently, I delved into Node and Express for server-side
            development and chose React as my front-end framework. On the server
            side, I acquired knowledge in MongoDB and SQLite for database
            management, and on the front end, I utilized EJS before
            transitioning to React and Vue.js. While I've used Bootstrap and
            Tailwind for front-end design, I prefer the flexibility of crafting
            my own components.
          </p>
          <p>
            Building upon this foundation, I decided to further enhance my
            skills by utilizing them in React Native, along with Expo, to create
            cross-platform applications for both iOS and Android. I have a
            strong command of Expo, a popular tool-set for developing React
            Native applications, which has enabled me to streamline the
            development process and deliver high-quality, feature-rich mobile
            apps.
          </p>

          <p>
            I've also gained experience in managing the challenges and
            intricacies of mobile app development, including performance
            optimization, API integration, and creating a seamless user
            experience. My passion for mobile app development has driven me to
            stay updated with the latest trends and best practices in this
            ever-evolving field.
          </p>
        </div>
      </div>
      <div class="about__content-section">
        <h2 class="about__content-title">
          Committed to Delivering Excellence...
        </h2>
        <div class="about__content-text">
          <p>
            I am dedicated to providing superior work to my clients and take
            immense satisfaction in my meticulous approach and commitment to
            perfecting my techniques.
          </p>
          <p>
            I derive great joy from continuously learning the latest
            technologies, implementing my newfound skills, and solving any
            challenges that may arise during the development process
          </p>
          <p>
            I am passionate about staying at the forefront of the ever-evolving
            web and app development landscape, ensuring that I can consistently
            deliver high-quality solutions to my clients.
          </p>
        </div>
      </div>
      <div class="about__content-section">
        <h2 class="about__content-title">My ability to communicate...</h2>
        <div class="about__content-text">
          <p>
            intricate concepts to colleagues and clients without technical
            backgrounds underscores my prowess as a collaborative force. My
            unwavering dedication to producing exemplary outcomes has not only
            garnered trust but also positioned me as a dependable and amicable
            professional.
          </p>
          <p>
            I take pride in my ability to translate complex technical ideas into
            easily comprehensible insights, fostering a smooth and productive
            working dynamic.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>