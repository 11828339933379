<template>
  <div class="button button__contact">
    <a
      href="mailto:nhargreavesdev@gmail.com"
      class="button__contact-link"
      target="blank"
    >
      <span class="material-symbols-outlined"> mail </span>
    </a>
  </div>

  <div class="button button__contact">
    <a
      href="https://www.linkedin.com/in/nick-hargreaves-85088223a/"
      class="button__contact-link"
      target="blank"
    >
      <ion-icon name="logo-linkedin"></ion-icon>
    </a>
  </div>

  <div class="button button__contact">
    <a
      href="https://github.com/Nbt53"
      class="button__contact-link"
      target="blank"
    >
      <ion-icon name="logo-github"></ion-icon>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style >
</style>
